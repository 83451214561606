.content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 1;
}

.quote {}

form {
    margin-top: 20px;
}


.email-dark {
    padding: 15px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 1em;
    border: 2px solid #464646;
    background-color: #2C2C2C;
    color: white;
    border-radius: 30px;
    outline: none;
    width: 300px;
    margin-right: 10px;
    transition: 0.3s;
}

.email-dark:focus {
    border: 2px solid #838383;
}

.email-light {
    padding: 15px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 1em;
    border: 2px solid #ccc;
    border-radius: 30px;
    outline: none;
    margin-right: 10px;
    width: 300px;
    transition: 0.3s;
}

.email-light:focus {
    border: 2px solid #464646;
}

.button-dark {
    margin-top: 10px;
    padding: 15px 20px;
    font-size: 1em;
    background-color: #fff;
    color: #2C2C2C;
    border: none;
    border-radius: 30px;
    font-weight: 600;
    cursor: pointer;
    transition: 0.3s;
}

.button-dark:hover {
    background-color: #b8b8b8;
}

.button-light {
    margin-top: 10px;
    padding: 15px 20px;
    font-size: 1em;
    background-color: #000;
    color: #fff;
    border: none;
    border-radius: 30px;
    font-weight: 600;
    cursor: pointer;
    transition: 0.3s;
}

.button-light:hover {
    background-color: #1e1e1e;
}

.scrolling-container {
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    position: relative;
}

.scrolling-wrapper {
    display: flex;
}

.scrolling-text {
    display: inline-block;
    white-space: nowrap;
}

.scrolling-item {
    display: inline-block;
    margin-right: 8px;
    /* Adjust spacing as needed */
}

.scrolling-text-top-font-light {
    color: #dcdcdc;
    font-size: 50px;
}

.scrolling-text-top2-font-light {
    color: #eaeaea;
    font-size: 50px;
}

.scrolling-text-top3-font-light {
    color: #f1f1f1;
    font-size: 50px;
}

.scrolling-text-top4-font-light {
    color: #f5f5f5;
    font-size: 50px;
}

.scrolling-text-top5-font-light {
    color: #fafafa;
    font-size: 50px;
}

.scrolling-text-top6-font-light {
    color: #fcfcfc;
    font-size: 50px;
}


.scrolling-text-top-font-dark {
    color: #848484;
    font-size: 50px;
}

.scrolling-text-top2-font-dark {
    color: #4B4B4B;
    font-size: 50px;
}

.scrolling-text-top3-font-dark {
    color: #333333;
    font-size: 50px;
}

.scrolling-text-top4-font-dark {
    color: #2A2A2A;
    font-size: 50px;
}

.scrolling-text-top5-font-dark {
    color: #1C1C1C;
    font-size: 50px;
}

.scrolling-text-top6-font-dark {
    color: #141414;
    font-size: 50px;
}

.background-light {
    background-color: #fff;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.background-dark {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #0F0F0F;
    height: 100%;
    width: 100%;
    overflow: hidden;
    margin: 0;
}