@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.nunito-sans-black {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
}

.nunito-sans-semi-bold {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
}

body {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    margin: 0;
}